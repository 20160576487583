<template>
  <div>
    <div class="table-header">
      <div class="theader-left">
        <span>单位管理</span>
      </div>
      <div class="theader-right">
          <div style="display: inline-block">
            <el-input  v-model.trim="inputValue" placeholder="单位名称" />
          </div>
            <el-button size="small" @click="handleQuery" type="primary">查询</el-button>
            <el-button size="small" @click="handleReset" style="margin-left: 0px">重置</el-button>
            
      </div>
    </div>
    <PublicTable ref="publicTable"></PublicTable>
  </div>
</template>

<script>
import table from "@/static/table.json";
//导入表格标题
import LoadData from "@/static/GetFileds/Company.json";
import PublicTable from "@/components/table/PublicTable.vue";

export default {
  components: { PublicTable },
  data() {
    return {
      inputValue: "", //搜索的内容
      options: [], //选项
      value: "", //当前选项
      url: "", //传给子组件的url
    };
  },
  methods: {
    //搜索
    handleQuery() {
      this.$refs.publicTable.getDataList(this.url, this.inputValue);
    },
    //重置
    handleReset() {
      this.inputValue = "";
      this.$refs.publicTable.getDataList(this.url, this.inputValue);
    },
  },
  mounted() {
    this.url = "/api/user/list";
    this.$refs.publicTable.getLoadData(LoadData, this.url, false);
    console.log("LoadData:", LoadData);
    // this.getDataList();
    this.options = table.options;
    this.value = this.options[0].value;

    // console.log(table.table);
  },
};
</script>

<style scoped>

.table-header {
  padding: 10px;
  overflow: hidden;
  /* border: 1px solid red; */
  box-shadow: 0 0 25px rgb(0 0 0 / 20%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid ; */
}
.table-header .el-select {
  width: 100px;
  margin: 0 10px 0 10px;
}
.theader-right :deep(.el-input input){
  height: 32px;
  width: 300px;
  margin-right: 3px;
  border-radius: 0px;
}
</style>